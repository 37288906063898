<template>
  <div>
    <div class="row justify-content-center mt-2">
      <div class="col-auto">
        <button @click="fetchIssues()" class="btn btn-primary">
          Résolution du problème
        </button>
      </div>
    </div>
    <div>
      <div v-if="isLoading">
        <center>
          <div class="loader"></div>
        </center>
      </div>

      <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
      <ul>
        <li
          v-for="issue in issues"
          :key="issue.id"
          @click="setCurrentIssue(issue)"
        >
          {{ issue.title }}
        </li>
      </ul>
      <div v-if="currentIssue">
        <h2>{{ currentIssue.title }}</h2>
        <p>{{ currentIssue.description }}</p>
        <button @click="resolveIssue(currentIssue)">Resolve</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("troubleshooting", [
      "issues",
      "currentIssue",
      "isLoading",
      "errorMessage",
    ]),
  },
  methods: {
    ...mapActions("troubleshooting", [
      "fetchIssues",
      "setCurrentIssue",
      "resolveIssue",
    ]),
  },
  created() {
    // this.fetchIssues();
  },
};
</script>
<style scoped>
.loader {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #fd1414;
  border-radius: 100%;
  animation: spin 1s linear infinite;
  margin: auto;
  margin-top: 40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
